// @flow
import React from 'react';
import type { FrontMatter, ImageType } from '../../utils/types';
import {
  AllImagesLink,
  CanyonRatingCard,
  Carousel,
  GlossaryLink as G,
  Image,
  Layout,
  Link,
  withFrontMatter,
} from '../../components';
import { graphql } from 'gatsby';
import route from '../../routes';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF1001: ImageType,
    P8080435: ImageType,
    IMG_8557: ImageType,
    IMG_8558: ImageType,
    IMG_8559: ImageType,
    IMG_8560: ImageType,
    IMG_8561: ImageType,
    IMG_8562: ImageType,
    IMG_8563: ImageType,
    IMG_8564: ImageType,
    IMG_8565: ImageType,
    IMG_8566: ImageType,
    IMG_8567: ImageType,
  },
};

class Blog_2021_08_08_Change_Creek extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          This is day four of my <G>PNW</G> Intro to <G>swift water</G>{' '}
          canyoneering course with{' '}
          <Link to="https://getinthewild.com/">Get In The Wild</Link>. Take a
          look at my{' '}
          <Link to={route.blog_2021_08_06_south_fork_snoqualmie}>
            Day One and Two
          </Link>{' '}
          and <Link to={route.blog_2021_08_07_hall_creek}>Day Three</Link> blog
          posts.
        </p>
        <CanyonRatingCard rating="3CII" title="Change Creek" />
        <p>
          Building on all the things we had learned up to this point in time,
          today we were almost exclusively in{' '}
          <Link to="http://ropewiki.com/Change_Creek">Change Creek</Link>.
        </p>
        <p>
          Side note: since the PNW had been in a prolonged drought combined with
          the fact that it rained quite a bit the day before, our lead
          instructor spent a very long time going over and over the potential
          for rockfalls and mudslides in the canyon. Consequently we entered the
          canyon shortly before the biggest rappel and skipped the upper
          section.
        </p>
        <p>
          My one experience with rockfall is detailed in my{' '}
          <Link to={route.blog_2014_09_20_rockfall_in_mystery_canyon}>
            Mystery Canyon
          </Link>{' '}
          blog post. Rockfalls are rarely talked about in canyoneering because
          they are so rare and once one happens there's almost no time to react.
          Someday I'll write an article on it.
        </p>
        <Image
          image={data.P8080435}
          caption="Most of us being lazy while the instructors set up the rappel"
        />
        <Carousel
          images={[
            data.IMG_8557,
            data.IMG_8558,
            data.IMG_8559,
            data.IMG_8560,
            data.IMG_8561,
            data.IMG_8562,
            data.IMG_8563,
            data.IMG_8564,
            data.IMG_8565,
            data.IMG_8566,
            data.IMG_8567,
          ]}
          captions={Array(11).fill(
            'Rappelling the big waterfall was the highlight of the trip - water running splashing everywhere and having to focus so I could breathe, several overhung lips so I had to be meticulous with my footwork and I couldn\'t see where they would go because of the waterfall, multiple stages so I could stop and have the "I\'m really doing this!" moment. I was so excited when I got to the bottom of that rappel that I yelled, "THIS MOMENT IS WHY I CAME HERE!". Photo credit: Jessie Coffey.'
          )}
        />
        <p>I had so much fun on this trip and can't wait to get back there!</p>
        <Image
          image={data._DSF1001}
          caption="The crew - Ben, Clark, Jess, Will, Jared, Sadegh, and Chris"
        />
        <AllImagesLink id="2021-08-08-change-creek" />
      </Layout>
    );
  }
}

export default withFrontMatter('2021-08-08-change-creek')(
  Blog_2021_08_08_Change_Creek
);

export const query = graphql`
  query {
    _DSF1001: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/_DSF1001.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    P8080435: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/P8080435.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8557: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8557.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8558: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8558.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8559: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8559.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8560: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8560.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8561: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8561.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8562: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8562.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8563: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8563.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8564: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8564.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8565: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8565.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8566: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8566.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    IMG_8567: file(
      relativePath: { eq: "images/blog/2021-08-08-change-creek/IMG_8567.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
